
<template>
  <BackofficeBase :loader_prop="loader_prop">

  <div id="nav">
    <router-link v-if = "view_data[0].id != '0'" :to="{path : '/backoffice/point_details', query : {'id' : view_data[0].distribution_point_id}}">פרטים</router-link> 
    <router-link v-if = "view_data[0].id == '0'" :to="{path : '/backoffice/point_details', query : {'id' :distribution}}">פרטים</router-link> |
    <router-link to="/backoffice/backoffice_users">סטטיסטיקה</router-link> |
    <router-link to="/backoffice/file_uploader">ניהול נק' חלוקה</router-link> |
  </div>
  <h1  v-if ="view_data[0].id != '0' ">עריכת יחידה</h1>
  <h1  v-if ="view_data[0].id == '0' ">הוספת יחידה</h1>
  <form @submit="backoffice_edit_unit">
    <div>
        :שם היחידה
      <div>
        <input v-model="name" type="text" v-bind:class="{input_error : name_input_error}" />
        <div class="input_error_txt" v-if="name_input_error">
          name error
        </div>
      </div>
      <div v-if ="view_data[0].id != '0' ">
         :IP כתובת
        <div>
          <input v-model = "ip_address" type = "text" v-bind:class="{input_error : ip_input_error}" />
          <div class="input_error_txt" v-if="ip_input_error">
            IP שגיאה בכתובת 
          </div>
        </div>
          :MAC כתובת
        <div>
          <input v-model = "mac_address" type = "text" v-bind:class="{input_error : mac_input_error}" />
          <div class="input_error_txt" v-if="mac_input_error">
             MAC שגיאה בכתובת 
          </div>
        </div>
      </div>
      
      <div v-if ="view_data[0].id == '0' ">
        :IP כתובת
        <div>
          <input v-model = "ip_address" type = "text" v-bind:class = "{input_error : ip_input_error}" />
          <div class="input_error_txt" v-if="ip_input_error">
            IP שגיאה בכתובת 
          </div>
        </div>
          :MAC כתובת
        <div>
          <input v-model = "mac_address" type = "text" v-bind:class = "{input_error : mac_input_error}" />
          <div class="input_error_txt" v-if="mac_input_error">
             MAC שגיאה בכתובת 
          </div>
        </div>
      </div>

      <div class="submit_cont">
        <input type="submit" value="Save" />
      </div>
      
    </div>
  </form>
    <p class = "unis_title">לאחר השמירה עדכן את גודל התיבות ביחידה</p>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: 'BackofficeEditUnits',
  components: {
    BackofficeBase
  },
  data() {
    return {
        loader_prop : true,
        view_data : [],
        name_input_error : false,
       
        
        distribution:''
        // ip_input_error : false,
        // mac_input_error : false
    }
  },
  async mounted() {
    this.id = this.$route.query.id
    let backendModel = new BackendModel()
    let view_data = await backendModel.backendRequest("/Api/service/backoffice/get_unit", {"id": this.id})
    if (this.id != 0){
      this.view_data = view_data.data.view_data
      console.log(this.view_data)
      this.loader_prop = false
      this.name = view_data.data.view_data[0].name
      this.ip_address = view_data.data.view_data[0].ip_address
      this.mac_address = view_data.data.view_data[0].mac_address
      this.distribution_point_id = view_data.data.view_data[0].distribution_point_id
    }else{
      this.view_data = [view_data.data.view_data]
      console.log(this.view_data)
      this.loader_prop = false
      this.distribution= this.$route.query.distribution
    }
  },

  methods : {
    async backoffice_edit_unit(e) {
      e.preventDefault()
      // Validation
      let errs = 0
      this.name_input_error = false
      if (this.name == '') {
        this.name_input_error = true
        errs++
      }
      if (errs == 0) {
        this.loader_prop = true
        let backendModel = new BackendModel()
        let params = {
          id : this.id,
          name : this.name,
          ip_address : this.ip_address,
          mac_address : this.mac_address,
          distribution: this.distribution
        }
        await backendModel.backendRequest("/Api/service/backoffice/edit_unit", params)
        this.loader_prop = false
        this.show_ty = true
        if(this.id != 0){
          // alert(this.distribution_point_id)
        await this.$router.push({ path: '/backoffice/point_management',query:{id : this.distribution_point_id}})
        }else{
          // alert(params.distribution)
        await this.$router.push({ path: '/backoffice/point_management',query:{id : params.distribution}})
        }
      }
    }
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

